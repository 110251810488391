import React from 'react';
import BookingForm from '../components/BookingForm';

const Home = () => {
  return (
    <div>
      <BookingForm />
    </div>
  );
}

export default Home;