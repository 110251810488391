import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-neutral-900 px-8">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div className="md:flex md:justify-between">
                <div className="mb-6 md:mb-0">
                    <a href="/" className="flex items-center md:mb-10 mb-16 w-fit">
                        <img src="../assets/img/logo.png" className="h-8 me-3" alt="DogSportCenter Wolfsburg Logo" />
                        <span className="text-white self-center text-lg md:text-2xl font-semibold whitespace-nowrap dark:text-white">DogSportCenter Wolfsburg</span>
                    </a>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-2 md:mb-16 mb-16">
                    <div>
                        <h2 className="text-white mb-6 text-sm font-bold uppercase dark:text-white">Dokumente</h2>
                        <ul className="text-zinc-500 dark:text-zinc-500 font-medium">
                            <li className="mb-4">
                                <a href="../assets/pdf/Allgemeine_Vertragsbedingungen.pdf" target="_blank" className="hover:underline hover:text-green-600 hover:cursor-pointer">Vertragsbedingungen</a>
                            </li>
                            <li className="mb-4">
                                <a href="../assets/pdf/Weitergehende_Buchungsvereinbarung.pdf" target="_blank" className="hover:underline hover:text-green-600 hover:cursor-pointer">Buchungsvereinbarung</a>
                            </li>
                            <li>
                                <a href="../assets/pdf/Weitergehende_Buchungsvereinbarung.pdf" target="_blank" className="hover:underline hover:text-green-600 hover:cursor-pointer">Hallenverordnung</a>
                            </li>
                        </ul>
                    </div>
                    {/*<div>
                        <h2 className="text-white mb-6 text-sm font-bold uppercase dark:text-white">Rechtliches</h2>
                        <ul className="text-zinc-500 font-medium">
                            <li className="mb-4">
                                <a href="/impressum" className="hover:underline hover:text-green-600 hover:cursor-pointer">Impressum</a>
                            </li>
                            <li className="mb-4">
                                <a href="/contact" className="hover:underline hover:text-green-600 hover:cursor-pointer">Kontakt</a>
                            </li>
                        </ul>
                    </div>*/}
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-zinc-500 sm:text-center dark:text-zinc-500">© 2023 <a href="https://luis-rusko.de" target="_blank" className="hover:underline hover:text-green-600">Luis Rusko</a>. Alle Rechte Einbehaltet.</span>
            </div>
            </div>
        </footer>
    );
};

export default Footer;