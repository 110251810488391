import React, { useState } from 'react';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
      setIsOpen(!isOpen);
    };
    
    return (
      <nav className="bg-neutral-900 fixed w-full z-20 top-0 start-0 md:px-8 pl-4 pr-0">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-5">
          <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src="../assets/img/logo.png" className="h-12" alt="DogSportCenter Wolfsburg Logo" />
          </a>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <a
              href="/#booking-form"
              className="text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none font-medium text-sm px-4 py-2 text-center dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-600"
            >
              Buchen
            </a>
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden focus:outline-none focus:ring-2"
              aria-controls="navbar-sticky"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Toggle main menu</span>
              {isOpen ? (
                <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              ) : (
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                </svg>
              )}
            </button>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isOpen ? 'block' : 'hidden'}`}
            id="navbar-sticky"
          >
            <ul className={`flex flex-col p-4 md:p-0 mt-4 md:opacity-100 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-neutral-800 dark:border-gray-700 transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
              <li>
                <a
                  href="/"
                  className="block text-lg z-10 py-0 px-3 text-white bg-transparent md:p-0 "
                  aria-current="page"
                >
                  Start
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
  );
}

export default Navigation;