import React from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Navigation from './components/Navigation';
import Banner from './components/Banner';
import Footer from './components/Footer';
import News from './pages/News';
import Home from './pages/Home';
import Events from './pages/Events';
import View from './pages/View';
import axios from 'axios';
import BookingForm from './components/BookingForm';

if (window.location.hostname === 'localhost') {
  axios.defaults.baseURL = 'http://localhost:8011/api/v1/';
} else {
  axios.defaults.baseURL = 'https://next.agiplus.de/api/v1/';
}

const App = () => {
  const pathnames = useLocation().pathname;
  
  return (
    <div className="bg-black">
      <Navigation />
      {pathnames.includes('news') ? <Banner title="News" /> : 
        pathnames.includes('events') ? <Banner title="Events" /> : 
        pathnames === "/" ? <Banner title="DogSportCenter Wolfsburg" /> : 
        null}
      <div className={`max-w-[1200px] h-[auto] ${pathnames === "/view" ? "mt-[5.5rem]" : "py-16"} mx-auto md:px-0`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/news" element={<News />} />
          <Route path="/events" element={<Events />} />
          <Route path="/view/:content/:id" element={<View />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;