import React, { useState } from 'react';

const View = () => {
    const [showFullText, setShowFullText] = useState(false);

    const fullText = `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.`;

    const displayedText = showFullText ? fullText : `${fullText.substring(0, 200)}...`;

    const toggleTextDisplay = () => {
        setShowFullText(!showFullText);
    };

    return (
        <div className='flex flex-col gap-4'>
            <div className='h-[80vh] md:h-[500px] relative overflow-hidden'
                style={{ backgroundImage: `url('http://localhost:3002/assets/img/banner/home-banner.png')`, backgroundPosition: 'center', backgroundSize: 'cover' }}
            >
                <div className='absolute bottom-0 backdrop-blur-[4px] w-full p-4 flex flex-col'>
                    <div className='flex gap-4 mb-2'>
                        <div className='flex bg-green-600 py-1 px-2 text-white'>
                            <i className='bx bx-run flex my-auto mr-1'></i>
                            <p className='my-auto'>Agility</p>
                        </div>
                        <div className='flex bg-green-600 py-1 px-2 text-white'>
                            <i className='bx bx-trophy flex my-auto mr-1'></i>
                            <p className='my-auto'>Turnier</p>
                        </div>
                    </div>
                    <h2 className='text-4xl md:text-5xl text-white font-bold mb-4'>Hop and Go Turnier</h2>
                    <div className='flex flex-col text-gray-100 gap-2 md:flex-row md:gap-8'>
                        <div className='flex'>
                            <i className='bx bx-calendar-alt my-auto mr-1'></i>
                            <p>18. Oktober 2024</p>
                        </div>
                        <div className='flex'>
                            <i className='bx bx-current-location my-auto mr-1'></i>
                            <p>DogsportCenter, Wolfsburg</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col py-4 md:flex-row'>
                <div className='w-1/1 px-4 py-6 md:p-6 flex md:hidden'>
                    <div className='flex flex-col w-fit h-fit md:ml-auto md:mr-0'>
                        <a href='#' className='text-white flex w-fit bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none font-medium text-lg px-4 py-2 text-center dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-600'>Zur Webseite</a>
                    </div>
                </div>
                <div className='w-1/1 md:w-1/2 p-4 md:px-6 md:py-6'>
                    <h3 className='text-green-600 text-2xl font-semibold mb-2'>Über das Turnier</h3>
                    <p className='text-white'>{displayedText}</p>
                    <button 
                        onClick={toggleTextDisplay} 
                        className='text-green-600 mt-2'>
                        {showFullText ? 'Weniger Erfahren' : 'Mehr Erfahren'}
                    </button>
                </div>
                <div className='w-1/2 p-6 hidden md:flex'>
                    <div className='flex flex-col w-fit h-fit ml-auto mr-0'>
                        <a href='#' className='text-white flex w-fit bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none font-medium text-lg px-4 py-2 text-center dark:bg-green-600 dark:hover:bg-green-600 dark:focus:ring-green-600'>Zur Webseite</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default View;