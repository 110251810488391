import React from 'react';
import '../App.css';

const Banner = ({title}) => {
    return (
        <div className="banner flex flex-col text-center justify-center w-full">
            <h1 className="mb-4 mx-auto text-5xl font-extrabold leading-none tracking-tight text-white w-[80vw]">{title}</h1>
        </div>
    );
}

export default Banner;